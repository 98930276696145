<template>
  <div class="health">
    <TopNavBar />
    <Carousel :carousels="carousels" />
    <div class="matrix_box">
      <Title title="产品矩阵" />
      <div class="matrixs">
        <div v-for="(item, index) in matrixs" :key="index" class="item">
          <h3
            :style="{
              backgroundImage: item.bag
                ? 'linear-gradient(#e7f2ff, #ffffff)'
                : '',
            }"
          >
            {{ item.title }}
          </h3>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: "Health",
  data() {
    return {
      matrixs: [
        {
          title: "智慧党建平台",
          content:
            "智慧党建系统结合党建实际工作，运用互联网+党建理念，构建了党员干部服务平台、党建管理平台、学习培训平台、统计分析平台、基础应用平台，满足党员、支部、党委日常所需。有效扩大党员的学习教育覆盖面，落实党员教育管理制度，实现了党建学习教育形式“由传统向现代、由封闭向开放、由实体向虚拟”的突破；提高党务工作办理效率和质量，实现党务工作管理的精细化、智能化、人文化和科学化。",
        },
        {
          title: "慢病随访平台",
          content:
            "慢病随访系统通过随访流程线上化、定制化问卷，实现内分泌（RA、SLE、SS）、呼吸（慢性阻塞性肺病、慢性阻塞性肺病急性加重、哮喘等）、脑卒中患者随访全流程管控。同时提供微信公众号宣教、健康组对照、标本管理等辅助功能便于医护人员使用。实现定期跟踪病情并记录在案，提高医院医前及医后服务水平，利于医学科研工作的开展和医务工作者业务水平的提高，从而更好地为患者服务",
          bag: true,
        },
        {
          title: "PDA移动护理",
          content:
            "移动护理工作站是以 PDA 作为硬件平台的手持终端执行系统，它是以医院信息系统为支持平台，在无线网络环境向病房的扩展和延伸的基础上，通过可移动的微型终端PDA，实现全院范围内的数据交换，以服务于护士相应的业务，推动医院数字化发展。",
          bag: true,
        },
        {
          title: "不良事件上报系统",
          content:
            "不良事件管理系统集成了上报-处理-整改-落实-预警整个流程，为医院内质量控制、患者安全关注、医疗安全不良事件方面的精细化的管理提供了平台，提高医疗质量相关事件的信息收集的效率和质量。",
        },
        {
          title: "胃癌筛查平台",
          content:
            "为深入贯彻落实党的十九大和习近平总书记关于人民健康安全系列重要讲话精神，牢固树立大卫生、大健康理念，提升我市肿瘤防治水平，通过对高危人群开展早期筛查，实现恶性肿瘤的早发现、早诊断、早治疗，提高恶性肿瘤的治愈率、延长患者生存期、降低疾病负担，提升生活质量。",
        },
        {
          title: "慢病申报系统",
          content:
            "慢病申报系统对门诊特慢特病申报流程进行优化，将原有线下申报迁移到线上申报，实现患者远程申报。通过系统有序管理，避免人工疏忽导致的资料丢失或资料不全情况。对接信息科接口获取患者医保信息，简化患者申报流程，实现快速申报及审批。促进“互联网+医保”的发展，完善医保线上服务。",
          bag: true,
        },
        {
          title: "区域不良事件上报平台",
          content:
            "不良事件管理系统为医院内质量控制、患者安全关注、医疗安全不良事件方面的精细化的管理提供了平台，可以提高医疗质量相关事件的信息收集的效率和质量，并及时的统计分析。系统支持PC端和手机移动端主体功能互联互通，较高级别事件上报后将对相关人员进行预警；",
          bag: true,
        },
        {
          title: "前列腺癌筛查平台",
          content:
            "疾病筛查平台将问卷数字化，简化了操作流程，提升了筛查录入效率，并将操作流程规范化，让癌症早发现、早治疗，促进医疗质量持续改进，后续数据通过各项指标可用于科研分析数据支撑来源，为前列腺癌后续更新治疗手段提供数据支持。",
        },
        {
          title: "合理化建议系统",
          content:
            "合理化建议是企业文化建设的一个重要组成部分，是提高员工整体素质的重要手段，帮助企业更好的落实合理化建议制度。本系统意在集团全员参与合理化建议、随时随地提出建议；并采取奖励机制，激发员工热情。实现合理化建议全闭环管理，建议件件有跟踪，事事有回应。",
        },
        {
          title: "自助机医患应用及综合管理系统",
          content:
            "依托现有就医业务流程，充分整合运用业内成熟的人工智能技术，构建全流程自助就医模式。系统结合院内智能化终端，面向患者提供基于脸、码、卡的多元就诊服务，实现患者脱卡就医、实名就医，优化就诊服务环节，简化就诊服务流程，提升医院精细化管理水平。",
          bag: true,
        },
        {
          title: "急诊时间节点管理系统",
          content:
            "本系统主要用于卒中、胸痛、创伤病人诊中治疗流程的管理与控制。通过读写器、手环等设备准确记录各个病人时间点；通过软件系统全局把控时间进度，并进行超时管理、填写治疗方案、治疗效果等，简化护士操作，规范处理流程，达到质控效果，帮助医院提升服务质量。",
          bag: true,
        },
        {
          title: "心理咨询服务小程序",
          content:
            "展示心理健康服务中心各类资讯项目及中心具体联系方式，为群众提供咨询预约、心理知识获悉、心理测评的有效途径，对心理防线较高的用户提供心理信箱抒发内心的负面情绪，专业人士将线上回复、梳理负面情绪，保障群众心理健康。",
        },
        {
          title: "健康评估系统",
          content:
            "实现健康影响线上评价，在制度及技术上对评价程序进行规范，建立健康影响评价专家库，并加强健康影响评价的监督管理；发挥公共政策对公众健康的导向作用，切实维护广大市民健康权益。大大提高基层工作人员的数据处理能力，提高工作效率；同时为领导决策提供依据、为政策制定提供建议、为政策落实提高精准度。",
        },
        {
          title: "综合支付对账平台",
          content:
            "综合支付对账平台集成医疗交易场景中常见的支付宝、微信、网银、各类电子卡等线上支付方式以及银行卡、社保卡、居民健康卡等线下支付方式于一体，面向医疗机构各业务系统提供统一交易服务，定制化诊间服务，基于统一的交易数据向医疗机构提供资金对账、财务服务以及监管决策服务。",
          bag: true,
        },
      ],
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/13.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.health {
  .matrix_box {
    margin: 85px 0 10px;
    .matrixs {
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(auto-fill, minmax(870px, 1fr));
      padding: 0 60px;
      margin-top: 70px;
      .item {
        box-shadow: 0px 0px 5px #86baea40;
        border-radius: 5px;
        width: 810px;
        margin-bottom: 70px;
        color: #333;
        h3 {
          padding: 10px 30px;
          font-family: "AlibabaPuHuiTi-Regular";
          font-weight: 400;
          font-style: normal;
          font-size: 25px;
          text-align: left;
          line-height: 50px;
          // border-bottom: 1px solid #ececec;
        }
        p {
          padding: 10px 30px 20px;
          font-family: "AlibabaPuHuiTi-Light";
          font-weight: 200;
          font-size: 20px;
          line-height: 40px;
          text-align: left;
        }
      }
      .item:hover {
        h3 {
          color: #006eff;
        }
      }
    }
  }
}
</style>
